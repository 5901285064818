//styling
import "../../App.css";
import HeaderLogo from "../../images/abbott-header-logo.png"
import HeaderImage from "../../images/img_header.png";
import "../../css/customer_portal.css";
import "../../css/normalize.css";


//react components
import React, { Component, useState, useEffect  } from "react";


//third-party components
import {createBrowserHistory} from "history";
import LinearProgress from '@mui/material/LinearProgress';


//customcomponents
import SignOut from '../Public/SignOut';
import AppRouter from './AppRouter';
import Footer from './Footer';



//libraries
import { GetUserInfo } from '../../libs/User';


export default function AuthenticatedContent(props){

    
    const [fullname,setFullName]=useState('');
    const [viewasname,setViewAsName]=useState('');
    const browserHistory = createBrowserHistory();
    const [path,setPath]=useState(undefined);
    const [initial,setInitial]=useState(true);
    const [isLoading,setIsLoading]=useState(true);
    const [isAdmin,setIsAdmin]=useState(false);
    const [plug,setPlug]=useState();
    

    const paths=['/links','/reports','/messages','/poc','/reports/viewer']
    const uri_stem = window.location.toString().match(/^https:\/\/.+?(\/.[^\?]+)/);

    useEffect(()=>{
        if(props.onNavigation){
            props.onNavigation();
        }
    },[]);


    useEffect(()=>{
        if(props.viewAs){
            setViewAsName(props.viewAs.user_name);
        }
    },[props.viewAs])

    

    useEffect(()=>{
        let userIdParam = window.location.toString().toLowerCase().match(/userid=(.[^\&]+)/);
        if(props.userInfo){
            
            setFullName(props.userInfo.user_name);
            if(props.userInfo.groups && props.userInfo.groups.indexOf('SysAdmin')>=0)
            {
                setIsAdmin(true);
            }
            
        }
        
        if(props.viewAs){
            setViewAsName(props.viewAs.user_name);
        }

        if(path){
            setInitial(true);
            let uri_stem = window.location.toString().match(/^https:\/\/.+?(\/.[^\?]+)/);
            if(uri_stem){
                if(paths.indexOf(uri_stem[1])>=0){
                    setPath(uri_stem[1]);
                }else{
                    setPath(paths[0]);
                }

            }
        }

        browserHistory.listen((e)=>{
            
            let crrpath=(!e.location.pathname || e.location.pathname=='/')?setPath(paths[0]):e.location.pathname;
            switch(e.action){
                case 'POP':
                    setInitial(true);
                    setPath(crrpath);
                    switchContent(crrpath,true);
                break;
                case 'PUSH':
                    setInitial(true);
                    setPath(crrpath);
                    switchContent(crrpath,true);
                break;
                default:
                    //console.log(e.action)
                break;
            }

        })

        if(props.onNavigation){
            props.onNavigation();
        }

        
        setIsLoading(false);
        
          
    },[path]);  //props.userInfo, props.viewAs, 

    const switchContent = (path,skipPush)=>{
        setInitial(false);
        if(!skipPush){
            browserHistory.push(path);
        }
        setPath(path);
        
    }

  

    
    if(uri_stem && uri_stem.length>=2 && uri_stem[1]=='/reports/viewer'){
        return (<AppRouter fullWindow={true} switchContent={switchContent} />)
        
    }else{
        return (
                (isLoading)?
                <LinearProgress />
                :
                <>
                <div className="admin_segment">
                    <div className="outcontainer apoc_customer_portal">
                        <div className="apoc_logo"><img src={HeaderLogo} width="120px" /></div>
                        <div className="apoc_title">
                            <span className="padding_right_5" style={{display: (fullname &&!viewasname)?'inline-block':'none'}}>Welcome, <span id="welcome">{fullname}!</span></span>
                            <span className="padding_left_5 padding_right_5" style={{display: (viewasname)?'inline-block':'none'}}>Viewing as - <span id="welcome">{viewasname}</span></span>
                            <span className="padding_right_5 padding_left_5">|</span>
                            {(isAdmin)?
                                <><span className="admin_link"><a href="/admin/customers">ADMINISTRATION CONTROL PANEL</a></span>
                                <span className="padding_right_20">|</span></>
                            :<></>
                            }
                            <SignOut 
                                onSignOut={props.onSignOut}
                                triggerSignOut={props.triggerSignOut}
                                onContinueSession={()=>{
                                    if(props.onContinueSession){
                                        props.onContinueSession();
                                    }
                                }}
                            />
                        </div>
                    </div>
                    </div>
                
                    <div className="background_white">
                        <div className="container_confirm confirmation">
                            <div className="confirm">
                            <div className="message"></div>
                            <div className="line_top"></div>
                            <div className="buttons">
                                <span className="yes">Yes</span>
                                <span className="cancel">No</span>
                            </div>
                            </div>
                        </div>
                    
                        
                        <div className="container_confirm alert">
                            <div className="confirm">
                            <div className="message"></div>
                            <div className="line_top"></div>
                            <div className="buttons">
                                <span className="ok">Ok</span>
                            </div>
                            </div>
                        </div>
                        <div className="padding_bottom_20 segment_padding">
                            <AppRouter fullWindow={false} path={path} switchContent={switchContent} />                            
                        </div>
                       
                    </div>
                    <Footer />

                </>
                
                
        );
    }
    
}